
import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from 'react-router-dom';
import loadable from '@loadable/component';
import axiosConfig from '../../utils/axiosConfig';

import { svgIcons, svgImgs } from '../../utils/svgs';
import { getCurrentAppType, fireClickstreamEvent, validate, sanitizeString } from '../../utils/utilities';
import * as utils from '../../utils/utilities';
import { logCSPageLoad } from '../../utils/tracking';
import { properties, apiUri } from '../../utils/properties';
import * as Actions from '../../store/actions/actions';
import ChangePasswordMeta from './ChangePasswordMeta';
import * as SimpleButtonAction from '../../components/SimpleButton/SimpleButton';
import * as loginState from '../../store/states/getLogin';
import $ from "jquery";

import LoginApi from '../../api/LoginApi';
import "../ForgotPassword/ForgotPassword.css";
import "./ChangePassword.css";

// const ErrorMessage = loadable(() => import('../../components/ErrorMessage/ErrorMessage'));
const SimpleButton = loadable(() => import('../../components/SimpleButton/SimpleButton'));



const ChangePassword = (props) => {

    const dobRef = useRef();
    const ssnRef = useRef();
    const newPassRef = useRef();
    const confirmNewPassRef = useRef();
    const resetUri = apiUri.pwReset;

    const { location, history, uTesting, testingPid } = props;
    const [acctInfo, setAcctInfo] = useState({
        requestStatus: ""
    });
    const [successLink, setSuccessLink] = useState("#");
    const [authStep, setAuthStep] = useState(null);
    const [chgPassStep, setChgPassStep] = useState(null);
    const [resetInfo, setResetInfo] = useState(null);
    const slashRegex = /\//ig;
    const dashRegex = /-/ig;

    const [btnEnabled, setBtnEnabled] = useState(true);

    useEffect(() => {
        if (authStep === null && location) {
            const urlParams = new URLSearchParams(location.search);
            if (urlParams.has('FMIID')) {
                const resetToken = sanitizeString(urlParams.get("FMIID"));
                const productId = sanitizeString(urlParams.get("pid"))
                if (resetToken && resetToken !== "test") {
                    let finalResetUrl = `${resetUri}/${resetToken}`;
                    const data = {
                        mode: "validate"
                    }
                    axiosConfig.put(finalResetUrl, data)
                        .then(function (response) {
                            // handle success
                            const res = response.data;
                            // setAuthStep("success");
                            setAcctInfo({
                                uid: res.username,
                                validation_token: res.validation_token
                            })
                            setAuthStep(1)
                            setChgPassStep(2);
                            fireClickstreamEvent({ 'type': 'display', 'name': 'change.request.step.2', category: "direct" });
                        })
                        .catch(function (err) {
                            const errData = err.response.data;
                            if (errData && errData.error && errData.error.code === "IDVerificationRequired") {
                                setAuthStep(1)
                                setChgPassStep(1);
                                fireClickstreamEvent({ 'type': 'display', 'name': 'change.request.step.1' });
                            } else {
                                setAuthStep("expired");
                                fireClickstreamEvent({ 'type': 'display', 'name': 'change.request.failed', category: "expired" });
                                $("#expiredLink").focus();
                            }
                        }).then(function () {
                            const resetObj = { token: resetToken }
                            if (productId) {
                                resetObj.pid = productId;
                            }
                            setResetInfo(resetObj);
                        })
                } else {
                    if (uTesting) {
                        setAcctInfo({
                            uid: "testing@fico.com",
                            validation_token: "123123"
                        })
                        setAuthStep(1)
                        setChgPassStep(1);
                        const resetObj = { token: "123" }
                        if (testingPid) {
                            resetObj.pid = testingPid;
                        }
                        setResetInfo(resetObj);
                    } else {
                        setAuthStep("failed");
                        $("#request-changed").focus();
                    }
                }
            } else {
                setAuthStep("failed");
                fireClickstreamEvent({ 'type': 'display', 'name': 'change.request.failed', category: "general" });
                $("#request-changed").focus();
            }
        }
    }, [location, authStep])

    const [dobInput, setDobInput] = useState(
        {
            id: 'input-container-dob',
            inputId: 'input_dob',
            // description: 'This may be your email address.',
            type: 'text',
            ariaLabel: 'Date of birth',
            currentValue: '',
            helperId: 'dob_helper',
            emptyErrorMessage: 'Required',
            description: `Enter the date in "MMDDYYYY" format.`,
            // description: `Example: Enter 0101${new Date().getFullYear() - 18} for 01/01/${new Date().getFullYear() - 18}`,
            errorMsg: ''
        }
    )
    const [ssnInput, setSsnInput] = useState(
        {
            id: 'input-container-ssn',
            inputId: 'input_ssn',
            // description: 'This may be your email address.',
            type: 'text',
            ariaLabel: 'Social Security number',
            currentValue: '',
            helperId: 'ssn_helper',
            emptyErrorMessage: 'Required',
            description: 'Enter SSN without dashes (-)',
            errorMsg: ''
        }
    )
    const [newPassInput, setNewPassInput] = useState(
        {
            id: 'input-container-new-pass',
            inputId: 'input_new_pass',
            // description: 'This may be your email address.',
            type: 'password',
            ariaLabel: 'New password',
            currentValue: '',
            helperId: 'new_pass_helper',
            emptyErrorMessage: 'Required',
            toggleBtnAriaLabel: 'visiblity of new password input',
            description: `Password must be at least 8 characters long, and must contain a mix of uppercase, lowercase, numbers and special characters.`,
            errorMsg: ''
        }
    )
    const [confirmNewPassInput, setConfirmNewPassInput] = useState(
        {

            id: 'input-container-new-pass',
            inputId: 'input_confirm_new_pass',
            // description: 'This may be your email address.',
            type: 'password',
            ariaLabel: 'Confirm new password',
            currentValue: '',
            helperId: 'confirm_new_pass_helper',
            emptyErrorMessage: 'Required',
            toggleBtnAriaLabel: 'visiblity of confirm new password input',
            description: '',
            errorMsg: ''
        }
    )

    useEffect(() => {
        if (props.updateState) {
            const setState = (updateStateName, updateStateValue) => {
                switch (updateStateName) {
                    case "authStep": setAuthStep(updateStateValue); break;
                    case "acctInfo": setAcctInfo(updateStateValue); break;
                    case "chgPassStep": setChgPassStep(updateStateValue); break;
                    default: return false;
                }
            }

            Object.keys(props.updateState).map((item) => {
                setState(item, props.updateState[item]);
                return true;
            })
        }
    }, [props.updateState])

    useEffect(() => {
        if (chgPassStep && authStep === 1) {
            const nextStepId = `cp_step_${chgPassStep}`;
            document.getElementById(nextStepId).focus()
        }
    }, [chgPassStep, authStep])

    const nextStepHandler = (step) => {
        if (step && step === 1) {
            setBtnEnabled(false);
            let inputValueDob = dobRef.current.value;
            let inputValueSsn = ssnRef.current.value;

            let isValidDob = validate.dob(inputValueDob);
            let isValidSsn = validate.ssn(inputValueSsn);

            if (isValidSsn) {
                const errorMsg = isValidSsn === true ? "" : isValidSsn;
                setSsnInput({ ...ssnInput, errorMsg: errorMsg });
                if (errorMsg) {
                    document.getElementById(ssnInput.inputId).focus()
                }
            }

            if (isValidDob) {
                const errorMsg = isValidDob === true ? "" : isValidDob;
                setDobInput({ ...dobInput, errorMsg: errorMsg });
                if (errorMsg) {
                    document.getElementById(dobInput.inputId).focus()
                }
            }

            if (isValidDob === true && isValidSsn === true && resetInfo !== null && resetInfo.token) {
                let finalResetUrl = `${resetUri}/${resetInfo.token}`;

                if (inputValueDob.indexOf("/") > -1) {
                    inputValueDob = inputValueDob.replace(slashRegex, "-")
                } else {
                    if (inputValueDob.indexOf("/") === -1 && inputValueDob.length === 8) {
                        inputValueDob = `${inputValueDob.substring(0, 2)}/${inputValueDob.substring(2, 4)}/${inputValueDob.substring(4)}`;
                    }
                }

                if (inputValueSsn.indexOf("-") > -1) {
                    inputValueSsn = inputValueSsn.replace(dashRegex, "")
                }

                const data = {
                    mode: "validate",
                    dob: inputValueDob,
                    ssn: inputValueSsn
                }


                if (uTesting) {
                    setAcctInfo({
                        uid: "testing@fico.com",
                        validation_token: "123123",
                        apiResError: null
                    })
                    setChgPassStep(2);
                    setBtnEnabled(true);
                } else {
                    axiosConfig.put(finalResetUrl, data)
                        .then(function (response) {
                            // handle success
                            const res = response.data;
                            // setAuthStep("success");
                            setAcctInfo({
                                uid: res.username,
                                validation_token: res.validation_token,
                                apiResError: null
                            })
                            setBtnEnabled(true);
                            setChgPassStep(2);
                            fireClickstreamEvent({ 'type': 'display', 'name': 'change.request.step.2' });
                        })
                        .catch(function (err) {
                            const errData = err.response.data;
                            if (errData.error.message && errData.error.message.toLowerCase().indexOf("locked") > -1) {
                                setAcctInfo({ ...acctInfo, requestStatus: "AccountLocked" });
                                setAuthStep("failed");
                                fireClickstreamEvent({ 'type': 'display', 'name': 'change.request.failed', category: "account.locked" });
                                $("#request-changed").focus();
                            } else if (errData.error.message && errData.error.message.indexOf("expired") > -1) {
                                setAuthStep("expired");
                                fireClickstreamEvent({ 'type': 'display', 'name': 'change.request.failed', category: "expired" });
                                $("#expiredLink").focus();
                            } else {
                                setAcctInfo({ ...acctInfo, "apiResError": "Please re-enter your information." });
                            }
                        }).then(function () {
                            setBtnEnabled(true);
                        })
                }
            } else {
                setBtnEnabled(true);
            }
        }

        if (step && step === 2) {
            if (!btnEnabled) {
                setBtnEnabled(true);
            }
            setChgPassStep(3);
            fireClickstreamEvent({ 'type': 'display', 'name': 'change.request.step.3' });
        }
    }

    const resetPasswordHandler = () => {
        setAuthStep(1);

        history.replace("/forgot-password")
    }

    const changePassHandler = () => {
        setBtnEnabled(false);
        const inputNewPass = newPassRef.current.value;
        const inputConfirmNewPass = confirmNewPassRef.current.value;

        let isValidNewPass = validate.checkPassString(inputNewPass);
        let isValidConfirmNewPass = ((inputConfirmNewPass !== "" && inputNewPass === inputConfirmNewPass)) ? true : false;

        if (!isValidConfirmNewPass && isValidNewPass === true) {
            let errorMsg = "New Password and Confirmation Password did not match. Re-enter both, making sure they are identical.";
            if (inputConfirmNewPass === "") {
                errorMsg = "Confirm Password is required."
            }
            setConfirmNewPassInput({ ...confirmNewPassInput, errorMsg: errorMsg });
            document.getElementById(confirmNewPassInput.inputId).focus();
        } else {
            setConfirmNewPassInput({ ...confirmNewPassInput, errorMsg: "" });
        }

        if (isValidNewPass) {
            const errorMsg = isValidNewPass === true ? "" : isValidNewPass;
            setNewPassInput({ ...newPassInput, errorMsg: errorMsg });
            if (errorMsg) {
                document.getElementById(newPassInput.inputId).focus()
            }

        }

        if (isValidNewPass === true && isValidConfirmNewPass === true && acctInfo !== null && acctInfo.validation_token) {
            let finalResetUrl = `${resetUri}/${resetInfo.token}`;
            const data = {
                mode: "set",
                validation_token: acctInfo.validation_token,
                password: inputNewPass,
                confirm_password: inputConfirmNewPass
            }
            if (uTesting) {
                if (testingPid) {
                    setAuthStep("success-continue");
                } else {
                    setAuthStep("success");
                }
            } else {
                axiosConfig.put(finalResetUrl, data)
                    .then(function (response) {
                        if (resetInfo.pid) {
                            setSuccessLink(`${properties.securewebserver}/order/start?product=${resetInfo.pid}`);
                            setAuthStep("success-continue");
                            fireClickstreamEvent({ 'type': 'display', 'name': 'change.request.success', category: "continue" });
                        } else {
                            let authLoginUrl = `${properties.authServer}/login`;
                            if (typeof window.isFinra === "string" && window.isFinra === "yes") {
                                authLoginUrl += "?skin=FINRA"
                            }
                            authLoginUrl += "#/";

                            setSuccessLink(authLoginUrl)
                            setAuthStep("success");
                            fireClickstreamEvent({ 'type': 'display', 'name': 'change.request.success', category: "login" });
                        }
                        $("#request-changed").focus();
                    })
                    .catch(function (err) {
                        const errData = err.response.data;
                        if (errData.error.message && errData.error.message.toLowerCase().indexOf("locked") > -1) {
                            setAcctInfo({ ...acctInfo, requestStatus: "AccountLocked" });
                            setAuthStep("failed");
                            fireClickstreamEvent({ 'type': 'display', 'name': 'change.request.failed', category: "account.locked" });
                            $("#request-changed").focus();
                        } else if (errData.error.message && errData.error.message.indexOf("expired") > -1) {
                            setAuthStep("expired");
                            fireClickstreamEvent({ 'type': 'display', 'name': 'change.request.failed', category: "expired" });
                            $("#expiredLink").focus();
                        } else {
                            setAcctInfo({ ...acctInfo, "apiResError": "Password criteria not met." });
                        }
                    }).then(function() {
                        setBtnEnabled(true);
                    })
            }
        } else {
            setBtnEnabled(true);
        }
    }

    const confirmNewPassInputToggle = () => {
        setConfirmNewPassInput({ ...confirmNewPassInput, type: confirmNewPassInput.type === "text" ? "password" : "text" });
    }

    const newPassInputToggle = () => {
        setNewPassInput({ ...newPassInput, type: newPassInput.type === "text" ? "password" : "text" });
    }

    const expiredResetPassword = {
        id: 'expired-reset-pass',
        buttonData: 'text',
        csName: 'expired.reset.password',
        text: 'Reset Password',
        action: resetPasswordHandler,
        ariaLabel: 'Click to start password reset'
    }

    const authNextStepButton = {
        id: 'step-1-next',
        buttonData: 'text',
        csName: 'step.1.authenticate.next',
        text: 'Next',
        action: () => nextStepHandler(1),
        ariaLabel: 'continue to next step'
    }
    const userIdNextStepButton = {
        id: 'step-2-next',
        buttonData: 'text',
        csName: 'step.2.userid.next',
        text: 'Next',
        action: () => nextStepHandler(2),
        ariaLabel: 'continue to next step'
    }
    const changePassBtn = {
        id: 'step-3-change-pass',
        buttonData: 'text',
        csName: 'step.3.change.pass.next',
        text: 'Change Password',
        action: changePassHandler,
        ariaLabel: 'click to update new password'
    }

    useEffect(() => {
        let isLocationCall = true;

        if (typeof location !== "undefined" && isLocationCall) {
            const locPath = location.pathname;
            logCSPageLoad(locPath);
        }

        return () => {
            isLocationCall = false;
        }
    }, []);

    const StepTitle = ({ stepNum, title, ariaCurrent, isValid }) => {
        return <h2 className={ariaCurrent ? "current" : ""} data-testid={`cp_step_${stepNum}`} id={`cp_step_${stepNum}`} tabIndex="0" aria-current={ariaCurrent} aria-label={`Step ${stepNum} of 3: ${title} ${isValid ? "(completed)" : ""}`}><span aria-hidden="true" className="step-circle">{(chgPassStep !== stepNum && stepNum < chgPassStep) ? <svg aria-hidden="true" className="stepValidCheck" dangerouslySetInnerHTML={{ __html: svgIcons.check }}></svg> : `${stepNum}`}</span>{title}</h2>
    }

    const dobOnBlurHandler = () => {
        let inputValue = dobRef.current.value;
        if (inputValue.indexOf("/") === -1 && inputValue.length === 8) {
            inputValue = `${inputValue.substring(0, 2)}/${inputValue.substring(2, 4)}/${inputValue.substring(4)}`;
            document.getElementById(dobInput.inputId).value = inputValue;
        }
        let isValidDob = validate.dob(inputValue);
        if (isValidDob) {
            const errorMsg = isValidDob === true ? "" : isValidDob;
            setDobInput({ ...dobInput, errorMsg: errorMsg });
            // if (errorMsg) {
            //     document.getElementById(dobInput.inputId).focus()
            // }
        }
    }
    const dobOnFocusHandler = () => {
        let inputValue = dobRef.current.value;
        let isValidDob = validate.dob(inputValue);
        if (isValidDob) {
            if (inputValue.indexOf("/") > -1) {
                inputValue = inputValue.replace(slashRegex, "")
            }
            document.getElementById(dobInput.inputId).value = inputValue;
        }
    }
    const ssnOnBlurHandler = () => {
        let inputValue = ssnRef.current.value;

        if (inputValue.indexOf("-") === -1 && inputValue.length === 9) {
            inputValue = `${inputValue.substring(0, 3)}-${inputValue.substring(3, 5)}-${inputValue.substring(5)}`;
            document.getElementById(ssnInput.inputId).value = inputValue;
        }
        let isValidSsn = validate.ssn(inputValue);
        if (isValidSsn) {
            const errorMsg = isValidSsn === true ? "" : isValidSsn;
            setSsnInput({ ...ssnInput, errorMsg: errorMsg });
        }
    }
    const ssnOnFocusHandler = () => {
        let inputValue = ssnRef.current.value;
        let isValidSsn = validate.ssn(inputValue);
        if (isValidSsn) {
            if (inputValue.indexOf("-") > -1) {
                inputValue = inputValue.replace(dashRegex, "")
            }
            document.getElementById(ssnInput.inputId).value = inputValue;
        }
    }

    return (
        <React.Fragment>
            <ChangePasswordMeta />
            <div className="bg auth-wrapper" >
                {authStep && authStep === "expired" &&
                    <div id="request-failed" className="auth-module centerContent expired-link" data-testid="change-password-expired">
                        <span aria-hidden="true" className="card-header-img" dangerouslySetInnerHTML={{ __html: svgImgs.expired }}></span>
                        <h2 id="expiredLink" className="sr-only">Your password reset link has expired</h2>
                        <p>Your password reset link has expired. Please attempt password reset again, or contact us.</p>
                        <div className="full-width-btn">
                            <SimpleButton {...expiredResetPassword} ariaLive="off" />
                        </div>
                    </div>
                }

                {authStep && authStep === 1 &&
                    <div className="auth-module" data-testid="change-password-steps">
                        <h1>Change Password</h1>
                        <ul data-component="Stepper" data-type="vertical" aria-label="Authenticate your account to change password" id="change-password-step">
                            <li data-status={(chgPassStep !== 1 && 1 < chgPassStep) ? "valid" : ""} >
                                <StepTitle stepNum={1} title="Authenticate" ariaCurrent={chgPassStep === 1 ? "step" : ""} isValid={(chgPassStep !== 1 && 1 < chgPassStep) ? true : false} />
                                <div data-show={chgPassStep === 1}>
                                    <form action="" onSubmit={(e) => { e.preventDefault() }}>
                                        {acctInfo && acctInfo.apiResError &&
                                            <div data-testid={`error-no-match`} className="form-error-container" id={`error-no-match`} aria-live="polite">
                                                <div className="error-message" id={`form-error-console-no-match`}>
                                                    <span className="icon-container"><svg aria-hidden="true" data-component="Icon" dangerouslySetInnerHTML={{ __html: svgIcons.error }}></svg></span>
                                                    <span className="text-container no-bottom-border" dangerouslySetInnerHTML={{ __html: acctInfo.apiResError }}></span>
                                                </div>
                                            </div>
                                        }
                                        <div data-component="Input" data-type="TextField" id={dobInput.id} data-valid={(dobInput && dobInput.errorMsg) ? false : true}>
                                            <input data-testid={dobInput.inputId} onBlur={dobOnBlurHandler} onFocus={dobOnFocusHandler} ref={dobRef} type={dobInput.type} id={dobInput.inputId} aria-label={dobInput.ariaLabel} aria-describedby={dobInput.helperId} placeholder=" " autoComplete="off" autoCapitalize="off" autoCorrect="off" required aria-required="true" aria-invalid={(dobInput && dobInput.errorMsg) ? true : false} />
                                            <div data-testid={`${dobInput.inputId}-label`} className="input-label" aria-hidden="true">{dobInput.ariaLabel}</div>

                                            <div className="input-helper-container">
                                                <div data-testid={dobInput.helperId} id={dobInput.helperId} className="description">{dobInput.description}</div>
                                                <div className="error-message" aria-live="polite">
                                                    <span className="icon-container"><svg aria-hidden="true" data-component="Icon" dangerouslySetInnerHTML={{ __html: svgIcons.error }}></svg></span>
                                                    <span data-testid={`${dobInput.helperId}-err`} className="text-container">{dobInput.errorMsg}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div data-component="Input" data-type="TextField" id={ssnInput.id} data-valid={(ssnInput && ssnInput.errorMsg) ? false : true}>
                                            <input data-testid={ssnInput.inputId} onBlur={ssnOnBlurHandler} onFocus={ssnOnFocusHandler} ref={ssnRef} type={ssnInput.type} id={ssnInput.inputId} aria-label={ssnInput.ariaLabel} aria-describedby={ssnInput.helperId} aria-required="true" placeholder=" " autoComplete="off" autoCapitalize="off" autoCorrect="off" aria-invalid={(ssnInput && ssnInput.errorMsg) ? true : false} />
                                            <div data-testid={`${ssnInput.inputId}-label`} className="input-label" aria-hidden="true">{ssnInput.ariaLabel}</div>

                                            <div className="input-helper-container" >
                                                <div data-testid={ssnInput.helperId} className="description" id={ssnInput.helperId} >{ssnInput.description}</div>
                                                <div className="error-message" aria-live="polite">
                                                    <span className="icon-container"><svg aria-hidden="true" data-component="Icon" dangerouslySetInnerHTML={{ __html: svgIcons.error }}></svg></span>
                                                    <span data-testid={`${ssnInput.helperId}-err`} className="text-container">{ssnInput.errorMsg}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="full-width-btn">
                                            <SimpleButton {...authNextStepButton} {...(btnEnabled) ? "" : { disabled: true }} />
                                        </div>
                                    </form>
                                </div>
                            </li>
                            <li data-status={(chgPassStep !== 2 && 2 < chgPassStep) ? "valid" : ""}>
                                <StepTitle stepNum={2} title="User ID" ariaCurrent={chgPassStep === 2 ? "step" : ""} isValid={(chgPassStep !== 2 && 2 < chgPassStep) ? true : false} />
                                {chgPassStep === 3 &&
                                    <div>
                                        <h3>Your user ID is:</h3>
                                        <span>{acctInfo.uid}</span>
                                    </div>
                                }
                                <div data-show={chgPassStep === 2} >
                                    <div data-testid="step-2-desc" aria-live="polite">
                                        <p>Use the following user ID next time you log in. Please take a moment to record it.</p>
                                        <div className="data-row">
                                            <h3>Your user ID is:</h3>
                                            <span>{acctInfo.uid}</span>
                                        </div>
                                    </div>
                                    <div className="full-width-btn">
                                        <SimpleButton {...userIdNextStepButton} ariaLive="off" />
                                    </div>
                                </div>
                            </li>
                            <li data-status={(chgPassStep !== 3 && 3 < chgPassStep) ? "valid" : ""}>
                                <StepTitle stepNum={3} title="New Password" ariaCurrent={chgPassStep === 3 ? "step" : ""} isValid={(chgPassStep !== 3 && 3 < chgPassStep) ? true : false} />
                                <div data-show={chgPassStep === 3}>
                                    <form action="" onSubmit={(e) => { e.preventDefault() }}>
                                        {acctInfo && acctInfo.apiResError &&
                                            <div data-testid={`error-invalid-match`} className="form-error-container" id={`error-invalid-match`} aria-live="polite">
                                                <div className="error-message" id={`form-error-console-no-match`}>
                                                    <span className="icon-container"><svg aria-hidden="true" data-component="Icon" dangerouslySetInnerHTML={{ __html: svgIcons.error }}></svg></span>
                                                    <span className="text-container no-bottom-border" dangerouslySetInnerHTML={{ __html: acctInfo.apiResError }}></span>
                                                </div>
                                            </div>
                                        }
                                        <p data-testid="step-3-desc" aria-live="polite">Enter your new password. A confirmation of this change will be emailed to you.</p>
                                        <div data-component="Input" data-type="TextField" id={newPassInput.id} data-valid={(newPassInput && newPassInput.errorMsg) ? false : true}>
                                            <input data-testid={newPassInput.inputId} ref={newPassRef} type={newPassInput.type} id={newPassInput.inputId} aria-label={newPassInput.ariaLabel} aria-describedby={newPassInput.helperId} autoComplete="off" autoCapitalize="off" autoCorrect="off" maxLength="128" placeholder=" " aria-invalid={(newPassInput && newPassInput.errorMsg) ? true : false} required aria-required="true" />
                                            <div data-testid={`${newPassInput.inputId}-label`} className="input-label" aria-hidden="true">{newPassInput.ariaLabel}</div>

                                            <div className="input-password-toggle-button-container">
                                                <button
                                                    data-testid={`btn_${newPassInput.inputId}`}
                                                    id={`btn_${newPassInput.inputId}`}
                                                    data-component="Button"
                                                    data-component-type="icon"
                                                    aria-pressed={newPassInput && newPassInput.type === "text" ? true : false}
                                                    data-controls={newPassInput.inputId}
                                                    aria-label={newPassInput.toggleBtnAriaLabel}
                                                    data-cs-type="click"
                                                    data-cs-name="new.password.display.toggle"
                                                    className="cselem"
                                                    onClick={newPassInputToggle}
                                                >
                                                    <svg data-component="Icon" aria-hidden="true" dangerouslySetInnerHTML={{ __html: svgIcons[newPassInput.type === "text" ? "show" : "hide"] }}></svg>
                                                </button>
                                            </div>

                                            <div className="input-helper-container">
                                                <div data-testid={newPassInput.helperId} className="description" id={newPassInput.helperId}>{newPassInput.description}</div>
                                                <div className="error-message" aria-live="polite">
                                                    <span className="icon-container"><svg aria-hidden="true" data-component="Icon" dangerouslySetInnerHTML={{ __html: svgIcons.error }}></svg></span>
                                                    <span data-testid={`${newPassInput.helperId}-err`} className="text-container">{newPassInput.errorMsg}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div data-component="Input" data-type="TextField" id={confirmNewPassInput.id} data-valid={(confirmNewPassInput && confirmNewPassInput.errorMsg) ? false : true}>
                                            <input data-testid={confirmNewPassInput.inputId} ref={confirmNewPassRef} type={confirmNewPassInput.type} id={confirmNewPassInput.inputId} aria-label={confirmNewPassInput.ariaLabel} aria-describedby={confirmNewPassInput.helperId} autoComplete="off" autoCapitalize="off" autoCorrect="off" maxLength="128" placeholder=" " aria-invalid={(confirmNewPassInput && confirmNewPassInput.errorMsg) ? true : false} required aria-required="true" />
                                            <div data-testid={`${confirmNewPassInput.inputId}-label`} className="input-label" aria-hidden="true">{confirmNewPassInput.ariaLabel}</div>

                                            <div className="input-password-toggle-button-container">
                                                <button
                                                    data-testid={`btn_${confirmNewPassInput.inputId}`}
                                                    id={`btn_${confirmNewPassInput.inputId}`}
                                                    data-component="Button"
                                                    data-component-type="icon"
                                                    aria-pressed={confirmNewPassInput && confirmNewPassInput.type === "text" ? true : false}
                                                    data-controls={confirmNewPassInput.inputId}
                                                    aria-label={confirmNewPassInput.toggleBtnAriaLabel}
                                                    data-cs-type="click"
                                                    data-cs-name="confirm.new.password.display.toggle"
                                                    className="cselem"
                                                    onClick={confirmNewPassInputToggle}
                                                >
                                                    <svg data-component="Icon" aria-hidden="true" dangerouslySetInnerHTML={{ __html: svgIcons[confirmNewPassInput.type === "text" ? "show" : "hide"] }}></svg>
                                                </button>
                                            </div>

                                            <div className="input-helper-container">
                                                <div data-testid={confirmNewPassInput.helperId} className="description" id={confirmNewPassInput.helperId}>{confirmNewPassInput.description}</div>
                                                <div className="error-message" aria-live="polite">
                                                    <span className="icon-container"><svg aria-hidden="true" data-component="Icon" dangerouslySetInnerHTML={{ __html: svgIcons.error }}></svg></span>
                                                    <span data-testid={`${confirmNewPassInput.helperId}-err`} className="text-container">{confirmNewPassInput.errorMsg}</span>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="full-width-btn">
                                            <SimpleButton {...changePassBtn} ariaLive="off" {...(btnEnabled) ? "" : { disabled: true }} />
                                        </div>
                                    </form>
                                </div>
                            </li>
                        </ul>
                    </div>
                }

                {authStep && (authStep === "success" || authStep === "success-continue") &&
                    <div aria-live="polite" id="request-success" className="auth-module centerContent" data-testid="change-password-success">
                        <span aria-hidden="true" className="card-header-img" dangerouslySetInnerHTML={{ __html: svgImgs.password_changed }}></span>
                        <h2 id="request-changed" className="sr-only">Password change successfully</h2>
                        <p>Your password has been changed and confirmation of this change has been emailed to you. Please keep your user ID and password in a secure place for future reference.</p>
                        <p>Note: 2-Step Verification has been turned off. Please remember to turn it on again.</p>
                        <div className="full-width-btn">
                            <a id="login-continue-btn" data-testid="login-continue-btn" className="cselem" data-cs-type="click" data-cs-name={`change.success.${authStep === "success" ? "login" : 'continue.purchase'}`} href={successLink} data-component="Button" data-component-type="standard" data-component-style="primary">{authStep === "success" ? "Log In" : 'Continue with Purchase'}</a>
                        </div>
                    </div>
                }

                {authStep && authStep === "failed" &&
                    <div aria-live="polite" id="request-failed" className="auth-module centerContent contact-support" data-testid="change-password-failed">

                        {(acctInfo && acctInfo.requestStatus && acctInfo.requestStatus === "AccountLocked") ?
                            <React.Fragment>
                                <span aria-hidden="true" className="card-header-img" dangerouslySetInnerHTML={{ __html: svgImgs.locked }}></span>
                                <h2 id="request-changed" className="sr-only">Account locked</h2>
                                <p>For your security, your account has been locked. Please contact us to unlock your account. You can also reach out to us using our <a className="cselem" data-cs-type="click" data-cs-name="locked.contact.us" href="https://www.myfico.com/support/contactus?subject=Other">contact form</a>.</p>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <span aria-hidden="true" className="card-header-img" dangerouslySetInnerHTML={{ __html: svgImgs.emailNotFound }}></span>
                                <h2 id="request-changed" className="sr-only">Please call us.</h2>
                                <p>Please contact myFICO Customer Support for help resetting your password. You can also reach out to us using our <a className="cselem" data-cs-type="click" data-cs-name="failed.contact.us" href="https://www.myfico.com/support/contactus?subject=Other">contact form</a>.</p>

                            </React.Fragment>
                        }
                        <p className="support-center-info">
                            <a className="info" href="tel:+18003194433"><span aria-hidden="true" dangerouslySetInnerHTML={{ __html: svgIcons.phone }}></span>1-800-319-4433</a>
                        </p>
                        <p className="support-center-info">
                            <span className="info">
                                <span className="clock-icon" aria-hidden="true" dangerouslySetInnerHTML={{ __html: svgIcons.clock }}></span>
                                <span aria-hidden="true">Mon - Fri: 6 AM - 6 PM, Sat: 7 AM - 4 PM Pacific Time</span>
                                <span className="sr-only">myFICO Customer support opens from monday to friday from 6am to 6pm and saturday from 7am to 4pm pacific time.</span>
                            </span>
                        </p>
                    </div>
                }

            </div>
        </React.Fragment>
    );
};

export default withRouter(ChangePassword);